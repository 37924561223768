<template>
  <div class="box">
    <div
        class="bg"
        style="position: absolute;top:0;width:100%;z-index:99;"
    >
      <div
          style="width:340px;height:200px;margin:0 auto;position:absolute;top:20%;left:50%;transform:translateX(-50%);background-color:#fff;border-radius: 10%;">
        <div
            style="width:74px;height:74px;border-radius: 50%;position: absolute;top:5%;left:38.9%;background-color:#f2fd95;overflow: hidden;">
          <img
              style="width:100%;height:100%"
              :src="self.headimgurl"
              alt=""
          >
        </div>
        <p style="font-size:18px;color:#967b3c;margin: 0;position: absolute;top:95px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          {{ nicknameFn(self.nickname, 9) }}</p>
        <p style="font-size:16px;color:#967b3c;margin: 0;position: absolute;top:130px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          你当前获得助力 {{ self.count }} </p>
      </div>
    </div>
    <div
        style="width:100%;margin:0 auto;position:absolute;top:260px;left:50%;transform:translateX(-50%);background-color:#fff;">
      <div
          style="font-size:16px;color:rgb(150, 123, 60);text-align:end;padding-right:15px;"
          @click="goRanings"
      >查看助力排行榜
      </div>
      <div
          v-for="(item,index) in assist"
          :key="index"
          style="width:100%;height:80px;padding: 0 26px;display:flex;justify-content:space-between;align-items: center;border-bottom: 1px solid #ccc; font-size:16px"
      >
        <div style="display: flex;align-items: center;">
          <!-- <div style="font-size:18px;min-width: 30px;">{{item.index}}</div> -->
          <div style="width: 60px;height:60px;border-radius:50%;overflow: hidden;margin-left: 5px;">
            <img
                style="width:100%;height:100%"
                :src="item.headimgurl"
                alt=""
            >
          </div>
          <div style="margin-left: 20px;font-size: 14px;">{{ nicknameFn(item.nickname, 9) }}</div>
        </div>
        <!-- <div style="font-size:18px;min-width: 30px;text-align:center;">{{item.count}}</div> -->
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      origin_: '',
      title: '',
      assist: [],
      self: {
        nickname: ''
      }
    }
  },
  methods: {
    goRanings() {
      // const reqData = this.getReqData(this.$route.query.data)
      // const raningsData = this.$resCommon.reqEncrypt({ project: reqData.project, uid: reqData.uid })
      // this.$router.push({ path: 'ranings', query: { data: this.$route.query.data } })
      this.$router.push({path: 'ranings', query: {project: this.$route.query.project, uid: this.$route.query.uid}})
    },
    // getReqData (data) {
    //   // 解密页面参数，获取具体的参数内容转回json格式并返回
    //   return this.$resCommon.resDecrypt(data)
    // },
    async getProfileInfo() {
      // const reqData = this.getReqData(this.$route.query.data)
      // let params = {
      //   project: reqData.project,
      //   uid: reqData.uid
      // }
      // const reqParams = this.$resCommon.reqEncrypt(params)
      this.origin_ = location.origin
      let params = {
        project: this.$route.query.project,
        uid: this.$route.query.uid,
      }
      // const res = await axios.post(`${this.origin_}/backend/poster/data`, {data: this.$route.query.data})
      const res = await axios.get(`${this.origin_}/backend/poster/data`, {params})
      console.log(res);
      // const resData = this.$resCommon.getResData(res)
      const resData = res.data.data;
      this.self = resData.self
      this.assist = resData.assist
    },

    //昵称处理
    nicknameFn(nickname, num) {
      if (nickname.length > num) {
        return nickname.substring(0, num) + '···'
      } else {
        return nickname
      }
    },
  },
  created() {
    this.getProfileInfo()
  },
  watch: {
    title: {
      handler(newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  }
}
</script>

<style scoped>
.box {
  position: relative;
  top: 0;
  z-index: 99;
  height: 100%;
  background-color: #cccccc1f;
}

.bg {
  width: 100%;
  height: 200px;
  background-color: #fb8c00;
  border-bottom-left-radius: 30%;
  border-bottom-right-radius: 30%;
}
</style>